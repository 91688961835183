import { Component, Vue } from 'vue-property-decorator'
import Layout from '@/layouts/basic/basic.vue';
import ShowPath from '@/components/showPath/showPath.vue';
import Title1 from '@/components/title1/title1.vue';
import ListCategoriaDocumentos from '@/modules/documentos/components/listCategoriaDocumentos/listCategoriaDocumentos.vue';
import {} from '@/components/base';
  
@Component({
  components: {
    Layout,
    Title1,
    ShowPath,
    ListCategoriaDocumentos
  }
})
export default class TodosDocumentos extends Vue {

  

}
  