import { Component, Vue } from 'vue-property-decorator'
import documentos from '@/modules/documentos/services/documentos'
import {} from '@/components/base';
  
@Component({
  components: {}
})
export default class ListCategoriaDocumentos extends Vue {

  mapTiposDoc = documentos.getAllMapTiposDoc() 

}
  